<template>
  <div class="reward-activity-info-block">
    <p class="card-title">扣款時機設定</p>
    <BaseElForm
      v-show="formData.periods"
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules"
      class="form"
    >
      <BaseElFormItem label="選擇扣款時機" prop="method">
        <BaseElSelect v-model="formData.method" placeholder="請選擇" clearable  :disabled="!!formData.orderCount">
          <BaseElSelectOption v-for="item in subscribePaymentDependTypeConfig" :key="item.value" :label="item.label" :value="item.value" />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem prop="downPaymentTime">
        <BaseElSelect v-model="formData.downPaymentTime" :disabled="!!formData.orderCount">
          <BaseElSelectOption
            v-for="option in subscribePaymentTimeTypeConfig"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
    <p v-if="formData.downPaymentTime && formData.periods" class="text-gray-80 text-sub" v-html="showDescription" />
    <p v-show="!formData.periods" class="text-gray-80 text-sub">請先輸入「單次訂閱期數」</p>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed } from 'vue'
import { noEmptyRules } from '@/validation'
import { subscribePaymentDependTypeConfig, subscribePaymentTimeTypeConfig } from '@/config/subscribe'
import { get } from 'lodash'

export default defineComponent({
  name: 'SubscribePaymentTimeBlock',
  components: { },
  props: ['FormsContext', 'planData'],
  setup (props) {
    const formRef = ref(null)
    const formData = reactive({
      method: 'subscribe',
      downPaymentTime: 'beforeUsed',
      periods: null,
      orderCount: 0,
    })
    const formRules = {
      method: [noEmptyRules()],
      downPaymentTime: [noEmptyRules()],
    }
    const syncData = () => {
      const data = props.planData
      formData.method = get(data, 'paymentTimeSettings.method')
      formData.downPaymentTime = get(data, 'paymentTimeSettings.fields.downPaymentTime')
      formData.periods = get(data, 'periodSettings.fields.periods')
      formData.orderCount = get(data, 'orderCount')
    }

    const compactData = computed(() => {
      const data = {
        paymentTimeSettings: {
          method: get(formData, 'method'),
          fields: {
            downPaymentTime: get(formData, 'downPaymentTime'),
          },
        },
      }
      return { ...data }
    })
    const showDescription = computed(() => {
      if (formData.downPaymentTime === 'beforeUsed') {
        return '扣款時機說明：<br>會員訂閱當下，隨即扣款當期的訂閱費用。<br>下次扣款日為 1 個月後。'
      } else if (formData.downPaymentTime === 'afterUsed') {
        return '扣款時機說明：<br>會員訂閱當下不扣款，直到下次扣款日 ( 1 個月後) 才開始扣款。'
      }
      return ''
    })
    onMounted(async () => {
      await nextTick()
      props.FormsContext.setFormRef('paymentTime', formRef.value)

      if (get(props.planData, 'id')) syncData()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('paymentTime', { ...compactData.value })
    })
    watch(props.planData, (data) => {
      formData.periods = data.periods
    })

    return {
      formRef,
      formData,
      formRules,
      subscribePaymentDependTypeConfig,
      subscribePaymentTimeTypeConfig,
      showDescription,
    }
  },
})
</script>

<style lang="postcss" scoped>
.form {
  @apply grid gap-[8px] grid-cols-2 w-[560px] items-end;
}
::v-deep .el-input, .el-select {
  @apply w-full;
}
</style>
