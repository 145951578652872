<template>
  <div class="reward-activity-info-block">
    <p class="card-title">合約設定</p>
    <BaseElForm
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <BaseElFormItem prop="contractId">
        <BaseElSelect v-model="formData.contractId" placeholder="請選擇" clearable :disabled="!!formData.orderCount">
          <BaseElSelectOption v-for="item in subscribeContractList" :key="item.id" :label="item.name" :value="item.id" />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed } from 'vue'
import { get } from 'lodash'
import { useSubscribe } from '@/use/useSubscribe'

export default defineComponent({
  name: 'SubscribeDurationBlock',
  components: { },
  props: ['FormsContext', 'planData'],
  setup (props) {
    const { getSubscribeContract, subscribeContractList } = useSubscribe()

    const formRef = ref(null)
    const formData = reactive({
      contractId: null,
      orderCount: 0,
    })
    const formRules = {}
    const syncData = () => {
      const data = props.planData
      formData.contractId = get(data, 'planContracts[0].contract.id')
      formData.orderCount = get(data, 'orderCount')
    }
    const compactData = computed(() => {
      const data = {
        contract: get(formData, 'contractId') ? { signatureRequired: true, contractId: get(formData, 'contractId') } : null,
      }
      return { ...data }
    })
    onMounted(async () => {
      await nextTick()
      await getSubscribeContract()
      props.FormsContext.setFormRef('contractId', formRef.value)

      if (get(props.planData, 'id')) syncData()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('contractId', { ...compactData.value })
    })

    return {
      formRef,
      formData,
      formRules,
      subscribeContractList,
    }
  },
})
</script>

<style lang="postcss" scoped>
</style>
